.App {
  text-align: center;
}

.header {
  margin-top: 20px;
}

.content {
  margin-top: 20px;
}

.row {
  margin-top: 20px;
}

table {
  border-collapse: collapse;
  margin: 0 auto 0 auto;
}

table, th, td {
  border: 1px solid black;
}

.toggle {
  width: 80px;
  height: 30px;
  line-height: 30px;
}

.checked {
  background-color: cyan;
}
